<script>
import { Line, mixins } from 'vue-chartjs'

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    data: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    plugins: {
      type: Array,
      default: null,
    },
    styles: {
      type: Object,
      default: null,
    },
  },
  watch: {
    data: {
      deep: true,
      handler: 'reload',
    },
  },
  mounted() {
    this.renderChart(this.data, this.options, this.plugins, this.styles)
  },
  methods: {
    reload() {
      this.renderChart(this.data, this.options, this.plugins, this.styles)
    },
  },
}
</script>
