<template>
  <section
    v-if="dashboard"
    id="dashboard-ecommerce"
  >
    <b-row class="match-height">
      <b-table
        responsive="sm"
        :items="dataProduct"
      />
      <b-col lg="12">
        <b-row class="match-height mb-2">
          <b-col
            lg="3"
            md="3"
            xs="12"
          >
            <flat-pickr
              v-model="rangeDate"
              placeholder="Choisissez une date"
              class="form-control mr-1"
              :config="{ mode: 'range'}"
            />
          </b-col>
          <b-col :md="9">
            <v-select
              v-model="productList"
              placeholder="Produit"
              label="name"
              :options="productSelect"
              :reduce="product => product.name"
              :clearable="false"
              :multiple="true"
              class="invoice-filter-select"
              transition=""
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <b-card>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                Statistiques produits
              </b-card-title>
              <!-- <b-card-sub-title>Commercial networks and enterprises</b-card-sub-title> -->
            </div>
          </b-card-header>

          <!-- chart -->
          <b-card-body v-if="dataChart">
            <chartjs-component-line-chart
              :height="500"
              :data="dataChart"
              :options="chartjsData.lineChart.options"
              :plugins="plugins"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <b-card>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                Statistiques categories
              </b-card-title>
              <!-- <b-card-sub-title>Commercial networks and enterprises</b-card-sub-title> -->
            </div>
          </b-card-header>

          <!-- chart -->
          <b-card-body v-if="categoriesChart">
            <chartjs-component-line-chart
              :height="500"
              :data="categoriesChart"
              :options="chartjsData.lineChart.options"
              :plugins="plugins"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <b-card>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                Offre Duo - Total: {{ totalDuoOffer }}€
              </b-card-title>
              <!-- <b-card-sub-title>Commercial networks and enterprises</b-card-sub-title> -->
            </div>
          </b-card-header>
          <!-- chart -->
          <b-card-body v-if="productsOffer">
            <!-- <b-table
              responsive="sm"
              :items="dataChartLtv.datasets.map((d, key) => ({ key: d.key, ca: {...d.data}, ...d.ca }))"
            /> -->
            <b-table
              ref="refUserListTable"
              class="position-relative"
              :items="productsOffer"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="Aucune commande trouvée"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <b-card>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                Lifetime Value (Tableau)
              </b-card-title>
              <!-- <b-card-sub-title>Commercial networks and enterprises</b-card-sub-title> -->
            </div>
          </b-card-header>

          <!-- chart -->
          <b-card-body v-if="dataChartLtv">
            <!-- <b-table
              responsive="sm"
              :items="dataChartLtv.datasets.map((d, key) => ({ key: d.key, ca: {...d.data}, ...d.ca }))"
            /> -->
            <b-table-simple
              hover
              small
              caption-top
              responsive
            >
              <colgroup><col><col></colgroup>
              <colgroup><col><col><col></colgroup>
              <colgroup><col><col></colgroup>
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>Panier</b-th>
                  <b-th colspan="3">
                    1
                  </b-th>
                  <b-th colspan="3">
                    2
                  </b-th>
                  <b-th colspan="3">
                    3
                  </b-th>
                  <b-th colspan="3">
                    4
                  </b-th>
                  <b-th colspan="3">
                    5
                  </b-th>
                  <b-th colspan="3">
                    6
                  </b-th>
                  <b-th colspan="3">
                    7
                  </b-th>
                  <b-th colspan="3">
                    8
                  </b-th>
                </b-tr>
                <b-tr>
                  <b-th></b-th>
                  <template
                    v-for="(data, keyT) of dataChartLtv.datasets"
                  >
                    <b-th :key="keyT">N°</b-th>
                    <b-th :key="keyT">CA</b-th>
                    <b-th :key="keyT">Moy.</b-th>
                  </template>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(data, key) of dataChartLtv.datasets"
                  :key="key"
                >
                  <b-td class="text-right" variant="primary">
                    {{ data.key }}
                  </b-td>
                  <template
                    v-for="(d, keyD) of data.data"
                  >
                    <b-td :key="keyD">{{ d }}</b-td>
                    <b-td :key="keyD">{{ data.ca[keyD] }}</b-td>
                    <b-td :key="keyD">{{ data.ca[keyD] !== 0 && d !== 0 ? (data.ca[keyD] / d).toFixed(0) : 0 }}</b-td>
                  </template>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <b-card>
          <b-card-header>
            <div>
              <b-card-title class="mb-1">
                Lifetime Value
              </b-card-title>
              <!-- <b-card-sub-title>Commercial networks and enterprises</b-card-sub-title> -->
            </div>
          </b-card-header>

          <!-- chart -->
          <b-card-body v-if="dataChartLtv">
            <chartjs-component-bar-chart
              :height="500"
              :data="dataChartLtv"
              :options="chartjsData.barChart.options"
              :plugins="plugins"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BCardHeader, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTable,

} from 'bootstrap-vue'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentLineChart from '@core/components/charts-components/ChartjsComponentLineChart.vue'
import ChartjsComponentBarChart from '@core/components/charts-components/ChartjsComponentBarChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    ChartjsComponentLineChart,
    ChartjsComponentBarChart,
    flatPickr,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    vSelect,
  },
  data() {
    return {
      data: {},
      chartjsData,
      dashboard: {},
      dataProduct: [],
      dataChartLtv: {
        labels: [1, 2, 3, 4, 5],
        datasets: [],
      },
      categoryesLtv: [],
      productsOffer: [],
      totalDuoOffer: 0,
      tableColumns: [
        { key: 'name', label: 'Produit', sortable: true },
        { key: 'total', label: 'Somme', sortable: true },
        { key: 'offer', label: 'Offre', sortable: true },
      ],
      country: 'DE',
      countryOptions: ['ES', 'FR', 'DE', 'IT'],
      productSelect: [],
      productList: [
        'Le matelas latex naturel',
        'Sommier Tapissier Kipli',
        'Lit avec sommier bois massif Normandie',
        'Lit avec sommier en bois Picardie',
      ],
      rangeDate: null,
      dataChart: null,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  watch: {
    rangeDate: {
      handler: 'getData',
      deep: true,
    },
    productList: {
      handler: 'getData',
      deep: true,
    },
  },
  async created() {
    // data
    const filter = {}
    filter.created_at = { $gte: this.$moment().subtract(6, 'months').toDate().getTime(), $lte: this.$moment().endOf('days').toDate().getTime() }
    // eslint-disable-next-line object-curly-newline
    const { dataChart, ltvChart, productsOffer, totalDuoOffer, categoriesChart } = await this.$http.get('/admin/dashboard/products', { params: { filter, productList: this.productList } })
    this.dataChart = dataChart
    this.categoriesChart = categoriesChart
    this.productsOffer = productsOffer
    this.totalDuoOffer = totalDuoOffer
    this.dataChartLtv.datasets = ltvChart
    const { products } = await this.$http.get('/admin/blog/list-product/?country=all')
    this.productSelect = products
    // console.log(test)
    // this.$http.get('/ecommerce/data')
    //   .then(response => {
    //     this.data = response.data
    //     // ? Your API will return name of logged in user or you might just directly get name of logged in user
    //     // ? This is just for demo purpose
    //     const userData = getUserData()
    //     this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
    //   })
  },
  methods: {
    async getData() {
      try {
        const filter = {}
        if (this.rangeDate && this.rangeDate.includes('to')) {
          const dates = this.rangeDate.split(' to ')
          filter.created_at = { $gte: this.$moment(dates[0]).startOf('days').toDate().getTime(), $lte: this.$moment(dates[1]).endOf('days').toDate().getTime() }
        // eslint-disable-next-line no-irregular-whitespace
        } else {
          filter.created_at = { $gte: this.$moment().subtract(6, 'months').toDate().getTime(), $lte: this.$moment().endOf('days').toDate().getTime() }
        }
        const {
          dataChart, ltvChart, categoryesLtv, dataProduct,
        } = await this.$http.get('/admin/dashboard/products', { params: { filter, productList: this.productList } })
        this.dataChart = dataChart
        this.dataChartLtv.datasets = ltvChart
        this.categoryesLtv = categoryesLtv
        this.dataProduct = dataProduct
        // const categoriesData = []
        // // eslint-disable-next-line no-restricted-syntax
        // for (const chart of ltvChart) {
        //   // const dataMapped = { key: chart.key }
        //   const dataMapped = { key: chart.key, ...chart.data.map((c, key) => ({ key: c, ca: chart.ca[key] })) }
        //   categoriesData.push(dataMapped)
        // }
        // this.categoryesLtv = categoriesData
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
