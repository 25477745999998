<script>
import { Bar, mixins } from 'vue-chartjs'
// import Chart from 'chart.js'

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: {
    data: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  watch: {
    data: {
      deep: true,
      handler: 'reload',
    },
  },
  mounted() {
    this.renderChart(this.data, this.options)
  },
  methods: {
    reload() {
      this.renderChart(this.data, this.options, this.plugins, this.styles)
    },
  },
}
</script>
